import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class AppService {
  constructor() {}

  public getFullYear(): string {
    return moment().format("YYYY");
  }
}
