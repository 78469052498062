import { VERSION } from "@angular/core";
import * as moment from "moment";
import { AppService } from "./app.service";

declare var $: any; // jQuery reference

/**
 * Application constants
 */
export class AppConstants {
  public static APP_TITLE = "Khaleel Arbeji - MSc Web Science";
  public static DATE: string;
  public static APP_TITLE_VERSION: string;

  public static ANGULAR_VERSION = "Ng " + VERSION.full;
  public static BOOTSTRAP_VERSION = $.fn.tooltip.Constructor.VERSION;

  public static initializeDate(appService: AppService) {
    this.DATE = appService.getFullYear();
    this.APP_TITLE_VERSION = this.APP_TITLE + " - " + this.DATE;
  }
}
