import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  usdtWalletAddress = '0xb5ed36d17bc33d76605a226f2015f4773c58b133';
  btcWalletAddress = '0xb5ed36d17bc33d76605a226f2015f4773c58b133';
  ethWalletAddress = '0xb5ed36d17bc33d76605a226f2015f4773c58b133';


  constructor(private translateService: TranslateService) { }

  ngOnInit() {
  }

  get lang() {
    return this.translateService.currentLang;
  }

  copyToClipboard(item, tooltip, greeting: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.openTooltip(tooltip, greeting);
  }

  openTooltip(tooltip, greeting: string) {
    this.closeTooltip(tooltip)
    tooltip.open({ greeting });

  }

  closeTooltip(tooltip) {
    tooltip.close(tooltip);
  }


}
