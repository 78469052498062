import { AppConstants } from "./app.constants";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { Gtag } from "angular-gtag";
import { AppService } from "./app.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    public appService: AppService,
    private titleService: Title,
    private router: Router,
    public gtag: Gtag
  ) {
    gtag.pageview();
  }

  ngOnInit(): void {
    AppConstants.initializeDate(this.appService);
    this.titleService.setTitle(AppConstants.APP_TITLE_VERSION);
    this.initLanguageService();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  private initLanguageService(): void {
    // Hack to load all languages
    // If we don't do this, the first time we change language not all elements are properly translated
    this.translateService.reloadLang("de");

    // this languageCode will be used as a fallback when a translation isn't found in the current languageCode
    this.translateService.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use(this.translateService.getDefaultLang());
  }

  get lang() {
    return this.translateService.currentLang;
  }
}
