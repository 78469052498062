import { Component, ChangeDetectionStrategy } from "@angular/core";
import { AppService } from "../app.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  // TODO implement component

  constructor(public appService: AppService) {}
}
